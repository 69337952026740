import React from 'react';

import styles from "./renovationsSection.module.css";

import KitchenIcon from "../images/KitchenIcn.png";
import HomeIcon from "../images/HomeIcon.png";
import BasementIcon from "../images/BasementIcon.png";

const Renovations = () => (
  <div id="renovations" className={styles.container}>
    <h1>Renovations</h1>
    <div className={styles.iconsContainer}>
      <div className={styles.iconDiv}>
        <a href="/renovations">
          <div className={styles.iconDivImageContainer}>
            <img src={KitchenIcon} alt="Icon of a Kitchen"/>
          </div>
          <h2>Kitchens</h2>
          <h3>Let’s build your dream kitchen</h3>
        </a>
      </div>
      <div className={styles.iconDiv}>
        <a href="/renovations">
          <div className={styles.iconDivImageContainer}>
            <img src={HomeIcon} alt="Icon of a Home"/>
          </div>
          <h2>Homes</h2>
          <h3>Room by room or all at once</h3>
        </a>
      </div>
      <div className={styles.iconDiv}>
        <a href="/renovations">
          <div className={styles.iconDivImageContainer}>
            <img src={BasementIcon} alt="Icon of a Basement"/>
          </div>
          <h2>Basements</h2>
          <h3>Professional Man Cave Builders</h3>
        </a>
      </div>
    </div>
  </div>
);

export default Renovations;
