import React from 'react';
import Layout from '../components/layout';

import HeroHeader from '../components/hero';
import Renovations from '../components/renovationsSection';
import OurStorySection from '../components/ourStorySection';
import Testimonials from '../components/testimonials';
import Contact from '../components/contact';
import Footer from '../components/footer';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {faDove} from '@fortawesome/free-solid-svg-icons';

library.add(faDove);

const IndexPage = () => (
  <Layout>
    <HeroHeader></HeroHeader>
    <Renovations></Renovations>
    <OurStorySection></OurStorySection>
    <Testimonials></Testimonials>
    <Contact></Contact>
    <Footer></Footer>
    <ToastContainer/>
  </Layout>
)

export default IndexPage
