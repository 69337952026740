import React from 'react';

import styles from "./testimonials.module.css";

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [
        {
          text: 'Wow! As a not so handy couple we wanted to update our laundry room. We had a Pin' +
              'terest vision of what we wanted it to look like. We wanted new cabinets and to t' +
              'urn a closet into a mudroom as we felt it would be more functional for our famil' +
              'y.  Matt worked with us to make sure every detail was exactly how we wanted it t' +
              'o be. He made suggestions we would not have thought of that made the project eve' +
              'n better than we could have ever imagined. Matt always shows up promptly with on' +
              'ly the best quality of materials. He is a hard worker and we will be using him i' +
              'n the future for our renovations.',
          source: 'Carleen'
        }, {
          text: 'Thank you, Matt, for an excellent job - quick and efficient work. Keep up the hi' +
              'gh standard. I have no hesitation recommending him to any other business.',
          source: 'Tazeen'
        }, {
          text: 'Matt from Home and Bliss Renos was awesome! We wanted a kitchen renovation done ' +
              'on a budget and he delivered! From the design stages to completion of the renova' +
              'tion, communication was always open and informative. They used high quality prod' +
              'ucts to ensure the look and style was that we wanted. We are very satisfied and ' +
              'we will use them again in the future.',
          source: 'Calgary Home Owner'
        }, {
          text: 'Our friends recommended Matt & we\'re so glad we contacted him. Our bathroom was' +
              ' outdated, there was water damage and we didn\'t know what to do. The Home and B' +
              'liss team changed that for us and now we love our bathroom. They were very polit' +
              'e and attentive to our needs. Efficiency was crucial as we were on a tight timel' +
              'ine. We appreciate that they cleaned up everyday after they were done. We will h' +
              'ighly recommend to friends and family.',
          source: 'Calgary Home Owner'
        }
      ],
      currentTestemonial: null,
      testemonialIndex: 0,
      hideTestemonial: false
    };

    this.state.currentTestemonial = this.state.testimonials[0];
  }

  componentDidMount() {
    this.handleAutomaticTestemonialSwitching();
  }

  handleAutomaticTestemonialSwitching = () => {
    setInterval(() => {
      this.setState({hideTestemonial: true});
      let index = this.state.testemonialIndex;
      if (index < this.state.testimonials.length - 1) {
        index += 1
      } else if (index === this.state.testimonials.length - 1) {
        index = 0;
      }
      setTimeout(() => {
        this.setState({currentTestemonial: this.state.testimonials[index], testemonialIndex: index, hideTestemonial: false});
      }, 1200);
    }, 10000);
  }

  getCurrentTestemonial = () => {
    let {currentTestemonial} = this.state;

    return (
      <div
        className={(this.state.hideTestemonial)
        ? styles.testemonialHidden
        : styles.testemonial}>
        <p>"{currentTestemonial.text}"</p>
        <h4>-{currentTestemonial.source}</h4>
      </div>
    )
  }

  render() {
    return (
      <div id="testemonials" className={styles.container}>
        <h1>Testimonials</h1>
        <div className={styles.contentContainer}>
          <div>{this.getCurrentTestemonial()}</div>
        </div>
      </div>
    )
  }
}

// const Testimonials = () => ( );

export default Testimonials;
