import React from 'react';

import styles from "./ourStorySection.module.css";

import family from '../images/_DSC0319-min.jpg';

const OurStorySection = () => (
  <div id="ourstory" className={styles.container}>
    <h1>Our Story</h1>
    <div className={styles.contentContainer}>
      <img src={family} alt="Sylvia and Matt" className={styles.mobileImg}/>
      <div>
        <p>Hey! Nice to “e-meet” you. We’re Sylvia & Matt. What can we say? We love
          turning a house into a home. We purchased our first “fixer upper” in YYC in 2012
          and we haven’t been able to put down the hammer since then. Can’t stop. Won’t
          stop. We believe anything worth doing, is worth doing well especially when it
          comes to a home remodel. Our hope is that we can help renovate your space, add
          value to your home and inspire you by sharing our experiences.
        </p>
        <button
          className={styles.talkButton}
          onClick={() => {
          window
            .location
            .replace('/ourstory')
        }}>Learn More</button>
      </div>
      <img src={family} alt="Sylvia and Matt" className={styles.desktopImg}/>
    </div>
  </div>
);

export default OurStorySection;
